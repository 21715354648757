import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-2bde222c"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "content"
};
var _hoisted_2 = {
  class: "con-de"
};
var _hoisted_3 = {
  class: "title"
};
var _hoisted_4 = {
  key: 0,
  class: "time"
};
var _hoisted_5 = {
  key: 1,
  class: "time"
};
var _hoisted_6 = {
  key: 2,
  id: "content"
};
var _hoisted_7 = ["innerHTML"];
var _hoisted_8 = {
  class: "con-zicla"
};
var _hoisted_9 = {
  class: "con-zicla"
};
var _hoisted_10 = {
  style: {
    "margin-top": "15px"
  }
};
var _hoisted_11 = {
  class: "submit_btn"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_Head = _resolveComponent("Head");

  var _component_DownFileList = _resolveComponent("DownFileList");

  var _component_UpLoadFile = _resolveComponent("UpLoadFile");

  var _component_el_button = _resolveComponent("el-button");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_Head, {
    title: "工作计划/总结",
    back: true
  }), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.detail.title), 1), _ctx.detail.startTime ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.detail.startTime) + " - " + _toDisplayString(_ctx.detail.finishTime), 1)) : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.detail.modifiedTime), 1)), _ctx.type == '0' ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.detail.content), 1)) : (_openBlock(), _createElementBlock("div", {
    key: 3,
    id: "content",
    innerHTML: _ctx.detail.information
  }, null, 8, _hoisted_7))]), _withDirectives(_createElementVNode("div", _hoisted_8, " 未到开始时间 ", 512), [[_vShow, _ctx.statusFlag(_ctx.detail) == 'weikaishi']]), _withDirectives(_createVNode(_component_DownFileList, {
    ref: "downFileList"
  }, null, 512), [[_vShow, _ctx.statusFlag(_ctx.detail) == 'youfujian']]), _withDirectives(_createElementVNode("div", _hoisted_9, " 未上传附件 ", 512), [[_vShow, _ctx.statusFlag(_ctx.detail) == 'wufujian']]), _withDirectives(_createElementVNode("div", _hoisted_10, [_createVNode(_component_UpLoadFile, {
    ref: "uploadRef",
    limit: 5,
    onFile: _ctx.filechange
  }, null, 8, ["onFile"]), _createElementVNode("div", _hoisted_11, [_createVNode(_component_el_button, {
    type: "primary",
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.submitForm();
    })
  }, {
    default: _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.submitText), 1)];
    }),
    _: 1
  })])], 512), [[_vShow, _ctx.statusFlag(_ctx.detail) == 'shangchuan']])])], 64);
}